import { type ComponentPropsWithoutRef } from "react";

export type LogoSvgProps = ComponentPropsWithoutRef<"svg"> & {
  size?: number;
  light?: boolean;
};

export const LogoSvg = ({ light, size = 32, ...props }: LogoSvgProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 208.000000 202.000000"
      preserveAspectRatio="xMidYMid meet"
      {...props}
      fill={
        props.fill || light ? "hsl(210, 50%, 95%)" : "hsl(var(--foreground))"
      }
    >
      <g transform="translate(0.000000,202.000000) scale(0.100000,-0.100000)">
        <path
          d="M1437 1973 c-4 -3 -7 -282 -7 -620 0 -484 -3 -613 -12 -613 -7 0
-197 185 -423 413 -226 227 -437 437 -470 467 -34 30 -75 68 -93 84 -17 16
-34 28 -36 25 -6 -6 38 -54 249 -269 192 -197 544 -564 714 -745 62 -66 119
-134 127 -152 14 -32 20 -144 8 -156 -3 -3 -40 34 -82 81 -91 104 -283 312
-403 437 -143 150 -470 506 -539 588 -35 42 -69 77 -74 77 -5 0 -7 -74 -5
-172 2 -95 3 -404 1 -685 -2 -481 -3 -513 -19 -513 -38 0 -237 191 -345 330
l-28 35 0 -42 c0 -38 7 -49 86 -138 90 -101 161 -162 256 -222 44 -27 58 -42
58 -59 0 -13 -4 -24 -10 -24 -14 0 -104 51 -108 62 -2 4 -8 8 -13 8 -10 0
-112 73 -137 97 -23 22 -50 34 -57 24 -12 -20 105 -120 215 -183 150 -86 206
-108 271 -108 l64 0 -47 27 c-26 14 -49 31 -51 37 -3 6 -2 296 1 644 4 473 8
632 17 632 22 0 705 -730 909 -972 32 -38 61 -68 67 -68 5 0 9 324 9 840 l0
840 -43 0 c-24 0 -47 -3 -50 -7z"
        />
        <path
          d="M2070 423 c0 -19 -190 -211 -267 -269 -43 -33 -83 -63 -89 -69 -6 -5
-41 -26 -78 -45 -57 -30 -63 -35 -41 -38 35 -5 225 129 326 229 85 85 165 186
155 196 -3 3 -6 2 -6 -4z"
        />
        <path
          d="M2037 323 c-58 -72 -131 -147 -184 -190 -26 -21 -50 -41 -53 -44 -3
-3 -21 -14 -40 -25 -19 -10 -37 -21 -40 -24 -3 -3 -18 -12 -35 -20 -28 -13
-29 -14 -8 -18 22 -3 85 27 168 80 82 53 239 233 233 268 -2 17 -9 12 -41 -27z"
        />
      </g>
    </svg>
  );
};
